<script setup>
import MovieEntry from '../components/MovieEntry.vue'
import NewsEntry from '../components/NewsEntry.vue'
</script>
<template>
  <div>
    <div class="teaser">
      <div v-if="highlight" :style="imageStyleObject">
        <div class="teaser-shadow">
          <div class="container-fluid">
            <div class="teaser-box">
              <h1>
                <router-link :to="{name: 'Product', params: {slug: highlight[1].slug}}">{{highlight[1].translations[0].title}}</router-link>
              </h1>
              <p v-if="highlight[1].translations[0].description">{{highlight[1].translations[0].description}}</p>
              <p><router-link :to="{name: 'Product', params: {slug: highlight[1].slug}}" class="button">Jetzt lesen</router-link></p>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="teaser-block">

      </div>
    </div>
    <div class="container-fluid">

      <div class="header">
        <h2>
          Aktuelle Nachrichten
          <nav class="submenu">
            <router-link :to="{name: 'Newslist'}"> Alle Nachrichten </router-link>
            <router-link :to="{name: 'Features'}"> Alle Features </router-link>
          </nav>
        </h2>
      </div>
      <div class="clear"></div>
      <div class="news" v-if="news">
        <div v-for="news_item in news" :key="news_item[1].slug" class="entry">
          <NewsEntry :news="news_item[1]"/>
        </div>
        <div class="clear"></div>
      </div>
      <div v-else class="news-render-block"></div>

      <div class="left">
        <div class="header">
          <h2>
            Demnächst im Kino
          </h2>
        </div>
        <ul v-if="coming_soon" class="movie-list">
          <li v-for="movie in coming_soon" :key="movie[1].slug" class="movie-item">
            <i>{{ date_format(movie[1].dates[0].date) }}</i> -
            <router-link :to="{name: 'Product', params: {slug: movie[1].slug}}" class="movie-link">{{movie[1].translations[0].title}}</router-link>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="header">
          <h2>
            Jetzt im Kino
          </h2>
        </div>
        <ul v-if="coming_soon" class="movie-list">
          <li v-for="movie in in_cinema" :key="movie[1].slug" class="movie-item">
            <i>{{ date_format(movie[1].dates[0].date) }}</i> -
            <router-link :to="{name: 'Product', params: {slug: movie[1].slug}}" class="movie-link">{{movie[1].translations[0].title}}</router-link>
          </li>
        </ul>
      </div>
      <div class="clear"></div>

      <div class="header">
        <h2>
          Weitere Nachrichten
        </h2>
      </div>
      <div class="clear"></div>
      <div class="news" v-if="news_more">
        <div v-for="news_item in news_more" :key="news_item[1].slug" class="entry">
          <NewsEntry :news="news_item[1]"/>
        </div>
        <div class="clear"></div>
      </div>
      <div v-else class="news-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Newslist'}">Alle News</router-link>
      </nav>

      <div class="header">
        <h2>
          Aktuelle Filmkritiken
        </h2>
      </div>
      <div class="movies" v-if="movies">
        <div v-for="movie in movies" :key="movie[1].slug" class="entry">
          <MovieEntry :movie="movie[1]" />
        </div>
      </div>
      <div v-else class="movies-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Movies'}">Alle Kritiken</router-link>
      </nav>

      <div class="header">
        <h2>
          Exklusiv im Streaming
        </h2>
      </div>
      <div class="movies" v-if="streaming">
        <div v-for="movie in streaming" :key="movie[1].slug" class="entry">
          <MovieEntry :movie="movie[1]" />
        </div>
      </div>
      <div v-else class="movies-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Streamings'}">Mehr Streaming</router-link>
      </nav>

      <div class="header">
        <h2>
          Jahresfeatures
        </h2>
      </div>
      <div class="news" v-if="features">
        <div v-for="news_item in features" :key="news_item[1].slug" class="entry">
          <NewsEntry :news="news_item[1]"/>
        </div>
        <div class="clear"></div>
      </div>
      <div v-else class="news-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Features'}">Alle Features</router-link>
      </nav>

      <div class="header">
        <h2>
          Serienkritiken
        </h2>
      </div>
      <div class="movies" v-if="series">
        <div v-for="movie in series" :key="movie[1].slug" class="entry">
          <MovieEntry :movie="movie[1]" />
        </div>
      </div>
      <div v-else class="movies-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Series'}">Mehr Serien</router-link>
      </nav>

      <div class="header">
        <h2>
          Klassiker Revisited
        </h2>
      </div>
      <div class="movies" v-if="classics">
        <div v-for="movie in classics" :key="movie[1].slug" class="entry">
          <MovieEntry :movie="movie[1]" />
        </div>
      </div>
      <div v-else class="movies-render-block"></div>
      <nav>
        <router-link class="button" :to="{name: 'Classics'}">Mehr Klassiker</router-link><br><br>
      </nav>

    </div>
  </div>
</template>
<style scoped>
.left, .right {
  width: 50%;
  float: left;
}
@media (max-width: 768px) {
  .left, .right {
    width: 100%;
    float: none;
  }
}
.movie-list {
  list-style-type: none;
  padding: 0 0 0 20px;
}
.movie-item {
  text-align: left;
  padding: 5px 0;
}
</style>
<script>
import { dater } from '@/modules/utilities.js'
export default {
  data () {
    return {
      coming_soon: '',
      in_cinema: '',
      highlight: '',
      movies: '',
      streaming: '',
      news: '',
      news_more: '',
      features: '',
      series: '',
      classics: '',
      windowWidth: window.innerWidth
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    fetchData () {
      fetch('https://api.kino-total.net/products/?position=Top&order=-pk&limit=1', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.highlight = data.products[0]
            document.querySelector('head meta[name="description"]').setAttribute('content', 'Das unabhängige Filmmagazin.')
            document.querySelector('head title').textContent = 'Kino-Total.net'
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?type=movie&soon=True&order=theatrical&limit=12', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.coming_soon = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?type=movie&theatralic=True&order=-theatrical&limit=12', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.in_cinema = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?position=Highlight&order=-pk&limit=12', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.movies = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?position=Streaming&order=-pk&limit=6', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.streaming = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?position=Serienkritiken&order=-pk&limit=6', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.series = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/products/?position=Klassiker&order=-pk&limit=6', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.classics = data.products
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/news/?limit=2&category=news', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.news = data.news
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/news/?limit=6&offset=2&category=news', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.news_more = data.news
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/news/?limit=5&category=feature', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.features = data.news
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net')
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    imageStyleObject () {
      if (this.windowWidth > 1100) {
        return {
          backgroundImage: `url(${this.highlight[1].mainview[0].file})`,
          backgroundPosition: 'center top',
          backgroundSize: 'auto 100%',
          width: '100%',
          height: '440px',
          marginTop: '-440px'
        }
      } else if (this.highlight[1].mainmobile && this.highlight[1].mainmobile[0].file) {
        return {
          backgroundImage: `url(${this.highlight[1].mainmobile[0].file})`,
          backgroundPosition: 'center top',
          backgroundSize: 'auto 100%',
          width: '100%',
          height: '480px',
          marginTop: '-440px'
        }
      }
      return {
        backgroundImage: `url(${this.highlight[1].mainview[0].file})`,
        backgroundPosition: 'center top',
        backgroundSize: 'auto 100%',
        width: '100%',
        height: '480px',
        marginTop: '-440px'
      }
    }
  }
}
</script>
